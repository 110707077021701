import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/login.css'
import logo from '../assets/images/logos/PAZ_Apex_Logotipo-01.png'
import { Link  } from 'react-router-dom';
export default function Login() {
    const [credentials, setCredentials] = useState({
        correo: '',
        contrasena: ''
    });
    const [alert, setAlert] = useState(false);
    const [mensaje, setMensaje] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
    const handleMensaje = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
   

    const handleSubmit = async (e) => {
        e.preventDefault();

        

        /*
        try {
            const res = await fetch('http://localhost:3091/login', {
            //const res = await fetch('https://apexlpz.bajaferries.com.mx/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials)
            });
    
            const data = await res.json();
    
            if (res.ok) {
                // Si hay un mensaje para redirigir a un formulario específico
                // Guardar el token de sesión en localStorage o manejar la sesión como necesites
                localStorage.setItem('userId', data.userId);  // Guarda el userId en localStorage   

                if (data.redirectTo) {
                    console.log(`Redirigiendo a: ${data.redirectTo}`);
                    // Redirigir a la ruta basada en el valor de `redirectTo`
                    navigate(`/${data.redirectTo}`); // Esto redirige a '/diagnostico' o '/registro-completo'
                } else {
                    console.log('Inicio de sesión exitoso:', data);                    
                    navigate('/dashboard'); // Redirigir al usuario a una página de destino
                }
            } else {
                setAlert(true);
                console.error('Error en el inicio de sesión:', data.message);
            }
        } catch (error) {
            console.error('Error al enviar las credenciales:', error);
        }*/
    };
    

  return (
    <>
       <section className='login'>
        <div className='container'>
            <div className='form-content'>
                
                <form onSubmit={handleSubmit}>
                    <div className='login-box-logo'>
                        <img src={logo} alt='logo apex' class="logo"/>
                    </div>
                    <h2>Inicia sesión</h2>
                    <p>Ingresa correo y contraseña para acceder a tu cuenta</p>                    
                    {alert && (
                        <>
                            <label className='alertaMensaje'>Contraseña incorrecta.  </label> <br />
                        </>
                    )}
                    <div className='input-group'>
                        <label>Correo</label>
                        <input type='email' name='correo' value={credentials.correo} onChange={handleChange}/>
                    </div>
                    <div className='input-group'>
                        <label>Contraseña</label>
                        <input type='password' name='contrasena' value={credentials.contrasena} onChange={handleChange}/>
                    </div>
                    <div className='input-group'>
                        <label>¿Olvidaste tu contraseña? <Link to='/password-reset'> Reestablécela aquí </Link></label>
                    </div>
                    <div className='input-group'>
                        <button type='submit' disabled={!credentials.correo || !credentials.contrasena}>
                            Iniciar sesión 
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
    </>
  )
}
